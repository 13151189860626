import { ReactComponent as CurrentLocationIcon } from '@brand/icons/current-location.svg'
import { useCallback } from 'react'
import { getBoundingBoxByDistanceFromCentroid } from '@rentpath/location-utils'
import { getCurrentLocationUrl } from '../../utils/get-current-location-url'
import styles from './current-location-button.module.css'
import type { SearchParams } from '../../search-page.types'
import type { IPLocation } from '../../../request-data/lib/ip-location/ip-location.types'

type CurrentLocationButtonProps = {
  searchParams: SearchParams
  ipLocation: IPLocation
}

const BOUNDING_BOX_MILES_FROM_CENTROID = 5

export function CurrentLocationButton({
  searchParams,
  ipLocation,
}: CurrentLocationButtonProps) {
  const handleCurrentLocationClick = useCallback(
    function () {
      const maybeNavigate = (url: string | null) => {
        if (url) {
          window.location.assign(`${window.location.origin}${url}`)
        }
      }

      const onCurrentPositionSuccess = (pos: GeolocationPosition) => {
        const centroid = {
          lat: parseFloat(pos.coords.latitude.toFixed(7)),
          lng: parseFloat(pos.coords.longitude.toFixed(7)),
        }

        const bbox = getBoundingBoxByDistanceFromCentroid(
          centroid,
          BOUNDING_BOX_MILES_FROM_CENTROID
        )

        const currentLocationUrl = getCurrentLocationUrl(
          ipLocation,
          { ...searchParams, userLatLng: `${centroid.lat},${centroid.lng}` },
          bbox
        )

        maybeNavigate(currentLocationUrl)
      }

      const onCurrentPositionError = () => {
        delete searchParams.userLatLng

        const currentLocationUrl = getCurrentLocationUrl(
          ipLocation,
          searchParams
        )

        maybeNavigate(currentLocationUrl)
      }

      navigator.geolocation.getCurrentPosition(
        onCurrentPositionSuccess,
        onCurrentPositionError
      )
    },
    [ipLocation, searchParams]
  )

  return (
    <button
      onClick={handleCurrentLocationClick}
      className={styles.currentLocationButton}
    >
      <CurrentLocationIcon className={styles.currentLocationIcon} />
      Current Location
    </button>
  )
}
