import { LocationSearchTypes } from '../../../__generated__/api-types'
import type { IPLocation } from '../../request-data/lib/ip-location/ip-location.types'
import { getSearchPageUrlFromLocation } from './get-search-page-url-from-location'
import type { SearchParams } from '../search-page.types'
import { getLocationSeoPathFromCityState } from '@brand/search/get-location-seo-path-from-city-state'

export const getCurrentLocationUrl = (
  ipLocation: IPLocation | null,
  searchParams: SearchParams,
  bbox?: number[]
) => {
  let currentLocationUrl: string | null = null

  if (
    typeof ipLocation?.city === 'string' &&
    typeof ipLocation?.state === 'string'
  ) {
    const searchUrl = getSearchPageUrlFromLocation({
      location: {
        type: LocationSearchTypes.City,
        seoPath: getLocationSeoPathFromCityState(
          ipLocation.city,
          ipLocation.state
        ),
        bbox,
      },
      refinements: searchParams.refinements,
      propertyTypes: searchParams.propertyTypes,
      singlePropertyTypeFilterParam: searchParams['property-type'],
      userLatLng: searchParams.userLatLng,
      moveInDate: searchParams['move_in_date'],
      display: searchParams.display,
    })

    const queryParams = new URLSearchParams(searchUrl?.split('?')[1])

    if (searchParams.display === 'map') queryParams.set('display', 'map')

    const queryString = queryParams.toString()

    currentLocationUrl = `${searchUrl?.split('?')[0]}${
      queryString ? `?${queryString}` : ''
    }`
  }

  return currentLocationUrl
}
