import clsx from 'clsx'
import React, { Fragment } from 'react'
import styles from './highlighter.module.css'

export type HighlighterProps = {
  children: string
  className?: string
  ignoreCase?: boolean
  indexHighlighted?: number
  pattern: string
}

export function Highlighter(props: HighlighterProps) {
  // make characters literal instead of interpretting symbols as valid regex instructions
  // AKA sanitize input
  const test = new RegExp(
    `(${props.pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`,
    props.ignoreCase ? 'i' : ''
  )

  const segments = props.children.split(test)

  const highlightedIndex = segments.findIndex((segment) => {
    if (segment.toLowerCase() === props.pattern.toLowerCase()) return true

    return false
  })

  const highlightedSegment = segments[highlightedIndex]
  const firstSegment = segments.slice(0, highlightedIndex).join('')
  const lastSegment = segments.slice(highlightedIndex + 1).join('')

  return (
    <Fragment>
      {firstSegment}
      {highlightedSegment && (
        <span className={clsx(styles.highlighted, props.className)}>
          {highlightedSegment}
        </span>
      )}
      {lastSegment}
    </Fragment>
  )
}
