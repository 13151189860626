import { atomWithStorage, createJSONStorage } from 'jotai/utils'

export const emailPromptShownAtom = atomWithStorage<boolean>(
  'email_prompt_shown',
  false,
  createJSONStorage<boolean>(() => localStorage)
)

export const srpViewPromptShownAtom = atomWithStorage<boolean>(
  'srp_views_prompt_shown',
  false,
  createJSONStorage<boolean>(() => localStorage)
)

export const emailCaptureViewCountAtom = atomWithStorage<number>(
  'srp_view_count',
  0,
  createJSONStorage<number>(() => sessionStorage)
)

export const emailCaptureFavoriteCountAtom = atomWithStorage<number>(
  'email_capture_favorite_count',
  0,
  createJSONStorage<number>(() => localStorage)
)
