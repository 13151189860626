/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type SearchComboboxQueryVariables = Types.Exact<{
  query: Types.Scalars['String'];
}>;


export type SearchComboboxQuery = { locationSearch: Array<{ id?: string | null, seoPath?: string | null, name: string, type: Types.LocationSearchTypes }>, propertySearch: { listings: Array<{ name?: string | null, urlPathname?: string | null, location?: { city?: string | null, stateAbbr?: string | null } | null }> } };


export const SearchComboboxQueryDocument = `
    query SearchComboboxQuery($query: String!) {
  locationSearch(queryText: $query) {
    id
    seoPath
    name
    type
  }
  propertySearch(propertyName: $query, resultsPerPage: 10) {
    listings {
      name
      urlPathname
      location {
        city
        stateAbbr
      }
    }
  }
}
    `;

    export function createSearchComboboxQueryFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchSearchComboboxQuery(variables: SearchComboboxQueryVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<SearchComboboxQuery, SearchComboboxQueryVariables>(SearchComboboxQueryDocument, variables, headers);
      }
    }

