import clsx from 'clsx'
import React from 'react'

import styles from './truncate.module.css'

import type { PolymorphicPropsWithoutRef } from '@rentpath/core-components'
import type { ElementType, ReactNode } from 'react'

export const TruncateDefaultElement = 'p'

export type TruncateOwnProps = {
  className?: string
  children?: ReactNode
}

export type TruncateProps<
  T extends ElementType = typeof TruncateDefaultElement
> = PolymorphicPropsWithoutRef<TruncateOwnProps, T>

export function Truncate<
  T extends ElementType = typeof TruncateDefaultElement
>({ as, className, ...restProps }: TruncateProps<T>) {
  const Element: ElementType = as || TruncateDefaultElement
  return <Element className={clsx(className, styles.truncate)} {...restProps} />
}
