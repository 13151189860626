'use client'

import { ReactComponent as ArrowLeftIcon } from '@brand/icons/back.svg'
import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'
import { ReactComponent as SearchIcon } from '@brand/icons/search.svg'
import { ReactComponent as HomeIcon } from '@brand/icons/home.svg'
import { ReactComponent as LocationIcon } from '@brand/icons/location.svg'
import { ReactComponent as BookmarkIcon } from '@brand/icons/bookmark.svg'
import { Combobox } from '@headlessui/react'
import { maybePrefixWithSlash, scrollToElement } from '@rentpath/web-utils'
import { usePathname } from 'next/navigation'
import clsx from 'clsx'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import Cookies from 'js-cookie'
import type {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEventHandler,
  ReactNode,
} from 'react'
import {
  startTransition,
  useMemo,
  Fragment,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { Highlighter } from '../../../components/highlighter/highlighter'
import { Truncate } from '../../../components/truncate/truncate'
import { graphqlRequesterOptions } from '../../../config/graphql-requester-options'
import { emailCaptureViewCountAtom } from '../../email-capture/email-capture.store'
import { SEARCH_HISTORY_COOKIE } from '../hooks/search-history.const'
import type { SearchParams } from '../search-page.types'
import { useRouteParams } from '../route-params.context'
import { getSearchPageUrlFromLocation } from '../utils/get-search-page-url-from-location'
import { useHandleClickOutside } from '@rentpath/react-hooks'
import type { SearchComboboxQuery } from './__generated__/search-combobox.gql'
import { createSearchComboboxQueryFetcher } from './__generated__/search-combobox.gql'
import styles from './search-combobox.module.css'
import { searchComboboxToScrollyOffset } from '@brand/slots/home/home-page-hero/search-combobox-to-scroll.const'
import { searchComboboxOpened } from './search-combobox.store'
import { Spinner } from '../../../components/spinner/spinner'
import { createLogger } from '../../logger/logger'
import type { OnSuccessCallbackParams } from '../../onboarding/hooks/use-show-onboarding-modal'
import { userSavedSearches } from '../../user/user.store'
import type { SavedSearchSummary } from '../../saved-searches/saved-search.types'
import { yieldOrContinue } from 'main-thread-scheduling'
import { CurrentLocationButton } from './current-location-button/current-location-button'
import { useRequestData } from '../../request-data/pages-router/use-request-data'

export const isNewSearchAtom = atom(false)

enum ResultType {
  SAVED_SEARCH,
  PLACE,
  PROPERTY,
}

export function getIsPropertyPath(result: SearchComboBoxItem) {
  return result?.resultType === ResultType.PROPERTY
}

type SearchComboBoxProps = {
  autoFetch?: boolean
  className?: string
  clearSearchTextButtonClassName?: string
  hideSearchButton?: boolean
  id?: string
  initialValue?: string | null
  isMobile?: boolean
  isModal?: boolean
  listItemClassName?: string
  loadingIndicatorClassName?: string
  noPlaceholder?: boolean
  onSuccess?: ({ res, setSuppressDropdown }: OnSuccessCallbackParams) => void
  onSelect?: (result: SearchComboBoxItem, isPropertyPath: boolean) => void
  placeholder?: string
  propertyTypes?: string
  recentSearch?: string
  resultsWrapperClassName?: string
  scrollToTopOnFocus?: boolean
  searchBoxFocusedClassName?: string
  searchButtonClassName?: string
  searchButtonNode?: ReactNode
  searchInputClassName?: string
  searchInputContainerClassName?: string
  searchResultsHeaderClassName?: string
  showClearSearchButton?: boolean
  showSearchIcon?: boolean
  callHandleSubmitOnEnter?: boolean
  appBannerDismissedCookie?: boolean
}

export type SearchComboBoxItem = {
  label: string
  value: string
  dataTagItem?: string
  type?: string
  resultType?: ResultType
}

export type SearchComboboxResults = {
  places: SearchComboBoxItem[]
  properties: SearchComboBoxItem[]
}

const fetchSearchComboboxQuery = createSearchComboboxQueryFetcher(
  graphqlRequesterOptions
)

const RESULTS_DEFAULT: SearchComboboxResults = {
  places: [],
  properties: [],
}

const logger = createLogger('search-combobox')

export function SearchComboBox(props: SearchComboBoxProps) {
  const {
    onSelect,
    appBannerDismissedCookie,
    isModal,
    scrollToTopOnFocus,
    autoFetch,
    initialValue,
    onSuccess,
    recentSearch,
    propertyTypes,
  } = props
  const defaultQueryValue = recentSearch || initialValue || ''
  const searchParams = useRouteParams()
  const { ipLocation } = useRequestData()
  const hasBbox = Boolean(searchParams.bbox)
  const isExactLocationSearch = Boolean(searchParams.userLatLng)
  const [isModalOpen, setIsModalOpen] = useAtom(searchComboboxOpened)
  const [showResults, setShowResults] = useState(false)
  const [suppressDropdown, setSuppressDropdown] = useState(false)
  const [noResultMessage, setNoResultMessage] = useState(false)
  const [currentSearchTerm, setCurrentSearchTerm] = useState('')
  const [results, setResults] = useState<SearchComboboxResults>(RESULTS_DEFAULT)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [query, setQuery] = useState(() =>
    isExactLocationSearch
      ? 'Current Location'
      : hasBbox
      ? 'Map Location'
      : defaultQueryValue
  )
  const [selection, setSelection] = useState<SearchComboBoxItem | null>(null)
  const hasResults = results.places.length > 0 || results.properties.length > 0
  const inputRef = useRef<HTMLInputElement | null>(null)
  const queryHolderRef = useRef('')
  const selectionHolderRef = useRef<SearchComboBoxItem | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isFocus, setIsFocus] = useState(false)
  const inputId = useId() + props.id || 'search-combobox'
  const setEmailCaptureViewCount = useSetAtom(emailCaptureViewCountAtom)
  const setIsNewSearchValue = useSetAtom(isNewSearchAtom)
  const searchComboboxRef = useRef<HTMLDivElement>(null)
  const activeIndexRef = useRef<number | null>(null)
  const SearchBoxIcon = props.showSearchIcon ? SearchIcon : CloseIcon
  const pathName = usePathname()
  useHandleClickOutside(
    containerRef,
    () => setShowResults(false),
    'click',
    showResults === true
  )

  const fetchResults = useCallback(
    (value: string) =>
      fetchAndTransformSearchComboBoxResults({
        query: value,
        refinements: searchParams.refinements,
        propertyTypes: propertyTypes || searchParams.propertyTypes,
        singlePropertyTypeFilterParam: searchParams['property-type'],
        moveInDate: searchParams['move_in_date'],
        pathName,
      }).tapError((err) => {
        setResults(RESULTS_DEFAULT)
        setShowResults(false)
        setLoading(false)
        logger.error(err)
      }),
    [searchParams, pathName, propertyTypes]
  )

  const handleChangeDebounced = useDebouncedCallback((value: string) => {
    if (!isFocus) {
      setQuery('')
      return
    }

    if (value.length >= 3) {
      setLoading(true)
      setNoResultMessage(false)
    } else {
      setLoading(false)
      setShowResults(false)
      setNoResultMessage(false)
      return
    }

    startTransition(() => {
      void fetchResults(value)
        .tapOk((res) => {
          setLoading(false)
          setError(null)
          if (res.places.length > 0 || res.properties.length > 0) {
            setResults(res)
            setNoResultMessage(false)
            setShowResults(true)
          } else {
            setResults(RESULTS_DEFAULT)
            setShowResults(false)
            setNoResultMessage(true)
          }
        })
        .tapError((err) => {
          setError(err)
        })
    })
  }, 200)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value
      setQuery(value)
      handleChangeDebounced(value)
      setIsFocus(true)
      setError(null)

      if (value === '' && hasResults) {
        if (!isModal) {
          setResults(RESULTS_DEFAULT)
          setShowResults(false)
        }
      }
    },
    [handleChangeDebounced, hasResults, isModal]
  )

  const handleInput = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setCurrentSearchTerm(value)
  }, [])

  const onBlur = useCallback(() => {
    setIsFocus(false)
    setError(null)
    setNoResultMessage(false)
    if (!currentSearchTerm) {
      if (queryHolderRef.current) {
        setQuery(queryHolderRef.current)
        queryHolderRef.current = ''
      }

      if (selectionHolderRef.current) {
        setSelection(selectionHolderRef.current)
        selectionHolderRef.current = null
      }

      if (!queryHolderRef.current && !selectionHolderRef.current) {
        setQuery(defaultQueryValue)
      }
    }
  }, [currentSearchTerm, defaultQueryValue])

  const handleBlur = useCallback(
    (e: FocusEvent) => {
      if (
        e.relatedTarget === searchComboboxRef.current ||
        searchComboboxRef.current?.contains(e.relatedTarget)
      )
        return

      if (isModalOpen) {
        return
      }

      onBlur()
    },
    [isModalOpen, onBlur]
  )

  const handleFocus = useCallback(
    async function handleFocus(e: FocusEvent) {
      if (e.target !== inputRef.current) {
        return
      }

      if (isModal) {
        await yieldOrContinue('interactive')
        setIsModalOpen(true)
      }

      setIsFocus(true)
      selectionHolderRef.current = selection
      setSelection(null)
      setIsNewSearchValue(false)
      queryHolderRef.current = query
      if (!currentSearchTerm) {
        setQuery('')
      }

      if (hasResults) {
        setShowResults(true)
      }

      if (scrollToTopOnFocus) {
        setTimeout(() => {
          void scrollToElement(
            containerRef.current,
            appBannerDismissedCookie ? -10 : searchComboboxToScrollyOffset
          )
        }, 100)
      }
    },
    [
      currentSearchTerm,
      hasResults,
      appBannerDismissedCookie,
      isModal,
      scrollToTopOnFocus,
      query,
      selection,
      setIsModalOpen,
      setIsNewSearchValue,
    ]
  )

  const handleSelection = useCallback(
    async (selectedItem: SearchComboBoxItem, suppressTagging = false) => {
      setIsNewSearchValue(true)

      const isPropertyPath = getIsPropertyPath(selectedItem)

      if (selectedItem) {
        setSelection(selectedItem)
        setIsFocus(false)
        const existingSearchHistory = Cookies.get(SEARCH_HISTORY_COOKIE)
        const searchHistory = existingSearchHistory
          ? JSON.parse(existingSearchHistory)?.[0]
          : {}
        if (isPropertyPath) {
          const searchHistoryEntry = [
            {
              ...searchHistory,
              propertyName: selectedItem.label,
            },
          ]
          await yieldOrContinue('idle')
          Cookies.set(SEARCH_HISTORY_COOKIE, JSON.stringify(searchHistoryEntry))
        } else if (searchHistory.locationSlug) {
          await yieldOrContinue('idle')
          setEmailCaptureViewCount((prev) => prev + 1)
        }

        queryHolderRef.current = ''
        selectionHolderRef.current = null
        setResults(RESULTS_DEFAULT)
        setIsModalOpen(false)
        onSelect?.(selectedItem, isPropertyPath)
        setShowResults(false)
        setCurrentSearchTerm('')
        // this is a work around to blur the input field after selection
        // this was included in headless per:
        // FIX: https://github.com/tailwindlabs/headlessui/discussions/2795
        // ISSUE: https://github.com/tailwindlabs/headlessui/pull/2272
        // not sure if they are going to fix it, but for not doing the
        // following is needed to blur our input on list item click
        requestAnimationFrame(() =>
          setTimeout(() => inputRef.current?.blur(), 0)
        )

        if (!suppressTagging) {
          await yieldOrContinue('idle')
          window.eventTracker?.track('click', {
            section: 'search_input',
            item: selectedItem.label,
          })
        }
      }
    },
    [onSelect, setEmailCaptureViewCount, setIsModalOpen, setIsNewSearchValue]
  )

  const allSavedSearches = useAtomValue(userSavedSearches)

  const savedSearches = useMemo(() => {
    return (allSavedSearches ?? [])
      .filter((savedSearch) => {
        return savedSearch.name.toLowerCase().startsWith(query.toLowerCase())
      })
      .map((search) => ({
        value: search.searchUrl,
        label: search.name,
      }))
      .slice(0, 3)
  }, [allSavedSearches, query])

  const handleSubmitButton = useCallback(
    async (e: { preventDefault: () => void }) => {
      e.preventDefault()
      const placesAndProperties = [...results.places, ...results.properties]
      // only try to use the activeDropdown label if places/properties have loaded
      const activePlacesAndPropertiesDropdownLabel =
        placesAndProperties.length > 0 &&
        typeof activeIndexRef.current === 'number' &&
        // subtract saved searches, which appear above place results, from the index
        placesAndProperties[activeIndexRef.current - savedSearches.length].label

      const searchTerm =
        activePlacesAndPropertiesDropdownLabel ||
        currentSearchTerm ||
        recentSearch

      if (
        typeof activeIndexRef.current === 'number' &&
        activeIndexRef.current < savedSearches.length &&
        !searchTerm
      ) {
        // If a saved search is highlighted - don't need to fetch results based on the search term
        const result = savedSearches[activeIndexRef.current]
        onSuccess?.({
          res: { savedSearches },
          setSuppressDropdown,
        })
        startTransition(() => {
          void handleSelection(result, true)
        })

        await yieldOrContinue('idle')
        window.eventTracker?.track('click', {
          section: 'search_input',
          item: 'search_button',
          customSearchInput: result.label, // maps to cd56
        })
      } else if (searchTerm) {
        fetchAndTransformSearchComboBoxResults({
          query: searchTerm,
          refinements: searchParams.refinements,
          propertyTypes: propertyTypes || searchParams.propertyTypes,
          singlePropertyTypeFilterParam: searchParams['property-type'],
          moveInDate: searchParams['move_in_date'],
          pathName,
        })
          .mapOk(async (res) => {
            const firstResult = [...res.places, ...res.properties][0]
            onSuccess?.({ res, setSuppressDropdown })
            startTransition(() => {
              void handleSelection(firstResult, true)
            })

            await yieldOrContinue('idle')
            window.eventTracker?.track('click', {
              section: 'search_input',
              item: 'search_button',
              customSearchInput: firstResult.label, // maps to cd56
            })
          })
          .mapError((err) => {
            setResults(RESULTS_DEFAULT)
            setError(err)
            setShowResults(false)
          })
        setCurrentSearchTerm('')
      }
    },
    [
      propertyTypes,
      results.places,
      results.properties,
      savedSearches,
      currentSearchTerm,
      recentSearch,
      onSuccess,
      handleSelection,
      searchParams,
      pathName,
    ]
  )

  const handleClearResults = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isModal) {
        setIsModalOpen(true)
        inputRef.current?.focus()
      } else {
        onBlur()
        e.currentTarget.blur()
        setSelection(null)
        setResults(RESULTS_DEFAULT)
      }

      inputRef.current?.focus()
      queryHolderRef.current = query
      setQuery('')
      setShowResults(false)
      setCurrentSearchTerm('')
    },
    [isModal, query, setIsModalOpen, onBlur]
  )

  const handleModalClose: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      setIsModalOpen(false)
      setShowResults(false)
      onBlur()
    },
    [onBlur, setIsModalOpen]
  )

  let value: Partial<SearchComboBoxItem> | string = selection || query

  if (isExactLocationSearch && !isFocus) {
    value = { value: '', label: 'Current Location' }
  } else if (hasBbox && !isFocus) {
    value = { value: '', label: 'Map Location' }
  }

  useEffect(() => {
    if (searchParams.location) {
      setSelection(null)
      setQuery(defaultQueryValue)
    }
  }, [defaultQueryValue, searchParams])

  useEffect(() => {
    if (autoFetch && initialValue) {
      startTransition(() => {
        fetchResults(initialValue).tapOk((res) => {
          if (res.places.length > 0 || res.properties.length > 0) {
            setResults(res)
          }
        })
      })
    }
  }, [fetchResults, autoFetch, initialValue])

  useEffect(() => {
    if (isModalOpen && results && isModal) {
      setShowResults(true)
    }
  }, [results, isModal, isModalOpen, showResults])

  const [
    shouldShowModalSearchLoadingIndicator,
    setShouldShowModalSearchLoadingIndicator,
  ] = useState(false)
  useEffect(() => {
    const speed = window.navigator?.connection?.effectiveType
    const timeout = speed && speed === '4g' ? 199 : 0

    // avoid flashing on fast connections
    const timer = setTimeout(() => {
      if (isModal) {
        setShouldShowModalSearchLoadingIndicator(loading)
      }
    }, timeout)
    return () => clearTimeout(timer)
  }, [loading, isModal])

  return (
    <div
      className={clsx(
        // The idea here is that if it's not a modal,
        // we use `display:contents` to just pass through,
        // as if this element didn't exist. Otherwise, this
        // element is used as the container for the fixed
        // positioned fullscreen container.
        props.isModal ? styles.isModal : styles.isNotModal,
        isModalOpen && styles.isModalOpen
      )}
    >
      <div
        className={clsx(
          'searchCombobox',
          styles.root,
          props.className,
          isFocus && props.searchBoxFocusedClassName
        )}
        data-tag_section="search_input"
        data-tid="autocomplete-search-bar"
        id={props.id}
        ref={containerRef}
      >
        <label htmlFor={inputId} className="sr-only">
          Search for homes by location
        </label>

        <Combobox
          as="div"
          nullable
          className={clsx(
            styles.searchContainer,
            props.searchInputContainerClassName
          )}
          value={value}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleSelection}
          ref={searchComboboxRef}
        >
          {/* Accessing Combobox renderProps to track the activeIndex */}
          {({ activeIndex }) => {
            activeIndexRef.current = activeIndex ?? 0

            return (
              <>
                <div className={styles.inputWrap}>
                  {props.isModal && isModalOpen && (
                    <button
                      aria-label="Cancel Search"
                      className={styles.backButton}
                      onClick={handleModalClose}
                      type="button"
                    >
                      <ArrowLeftIcon />
                    </button>
                  )}

                  <Combobox.Input
                    ref={inputRef}
                    id={inputId}
                    autoComplete="off"
                    onInput={handleInput}
                    className={clsx(styles.input, props.searchInputClassName)}
                    displayValue={() =>
                      typeof value === 'string' ? query : value?.label || ''
                    }
                    name="query"
                    type="search"
                    data-tid="search-input-field"
                    onKeyDownCapture={(e: KeyboardEvent<HTMLInputElement>) => {
                      // disable default behavior where headless
                      // auto-selects the first item on blur.
                      if (e.key === 'Tab') {
                        setShowResults(false)
                        onBlur()
                      }

                      if (e.key === 'Enter') {
                        if (props.callHandleSubmitOnEnter) {
                          void handleSubmitButton(e)
                        } else {
                          // If the instance is contained in a form, treat
                          // it like a normal input when a user presses "Enter".
                          e.currentTarget.form?.submit()
                        }
                      }
                    }}
                    onChange={handleChange}
                    placeholder={
                      props.noPlaceholder
                        ? ''
                        : props.placeholder ?? 'City, Neighborhood, ZIP'
                    }
                  />

                  {props.showSearchIcon && (
                    <span className={styles.searchIcon}>
                      <SearchBoxIcon aria-hidden="true" />
                    </span>
                  )}

                  {props.showClearSearchButton && (
                    <button
                      aria-label="Clear Search Result"
                      onClick={handleClearResults}
                      type="button"
                      className={clsx(
                        styles.clearSearchTextButton,
                        props.clearSearchTextButtonClassName
                      )}
                    >
                      <SearchBoxIcon aria-hidden="true" />
                    </button>
                  )}

                  {!props.hideSearchButton && (
                    <button
                      type="button"
                      onClick={handleSubmitButton}
                      className={clsx(
                        styles.searchButton,
                        props.searchButtonClassName
                      )}
                      data-tid="search-button"
                      data-tag_action="ignore"
                      aria-label={
                        typeof props.searchButtonNode !== 'string'
                          ? 'Submit Search'
                          : ''
                      }
                    >
                      {isModalOpen ? props.searchButtonNode : 'Search'}
                    </button>
                  )}

                  {loading && (
                    <div
                      aria-hidden
                      className={clsx(
                        styles.loadingIndicator,
                        props.loadingIndicatorClassName
                      )}
                    />
                  )}
                </div>

                {(showResults ||
                  // saved searches should appear on focus even if nothing is typed
                  (savedSearches.length > 0 && isFocus) ||
                  error !== null ||
                  noResultMessage) &&
                  !suppressDropdown && (
                    <Combobox.Options
                      static
                      className={clsx(
                        styles.listWrapper,
                        props.resultsWrapperClassName
                      )}
                      data-tag_action="ignore"
                    >
                      {error || noResultMessage ? (
                        <li>
                          {error
                            ? 'Sorry, there was an issue.'
                            : 'No Results Found'}
                        </li>
                      ) : (
                        <>
                          {shouldShowModalSearchLoadingIndicator ? (
                            <li className={styles.modalSearchLoadingIndicator}>
                              <Spinner />
                            </li>
                          ) : (
                            (savedSearches.length > 0 ||
                              results.places.length > 0 ||
                              results.properties.length > 0) && (
                              <>
                                {ipLocation?.city &&
                                  ipLocation?.state &&
                                  props.isModal && (
                                    <li
                                      className={clsx(
                                        styles.listItem,
                                        styles.currentLocationListItem
                                      )}
                                    >
                                      <CurrentLocationButton
                                        searchParams={searchParams}
                                        ipLocation={ipLocation}
                                      />
                                    </li>
                                  )}
                                {savedSearches.length > 0 && (
                                  <>
                                    <li
                                      className={clsx(
                                        styles.listHeader,
                                        props.searchResultsHeaderClassName
                                      )}
                                    >
                                      Saved Searches
                                    </li>
                                    {savedSearches.map((result, index) => (
                                      <Combobox.Option
                                        key={result.value + index}
                                        value={result}
                                        as={Fragment}
                                        data-tag_action="ignore"
                                      >
                                        {({ active, selected }) => (
                                          <li className={styles.listItemWrap}>
                                            <div
                                              className={clsx(
                                                styles.listItem,
                                                selected &&
                                                  styles.listItemHighlighted,
                                                active &&
                                                  styles.listItemHighlighted,
                                                props.listItemClassName
                                              )}
                                            >
                                              <BookmarkIcon
                                                className={styles.icon}
                                              />
                                              <Truncate as="span">
                                                <Highlighter
                                                  className={
                                                    styles.listItemTextHighlight
                                                  }
                                                  ignoreCase
                                                  pattern={query}
                                                >
                                                  {result.label}
                                                </Highlighter>
                                              </Truncate>
                                            </div>
                                          </li>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                  </>
                                )}
                                {results.places.length > 0 && (
                                  <>
                                    <li
                                      className={clsx(
                                        styles.listHeader,
                                        props.searchResultsHeaderClassName
                                      )}
                                    >
                                      Places
                                    </li>
                                    {results.places.map((result, index) => (
                                      <Combobox.Option
                                        key={result.value + index}
                                        value={result}
                                        as={Fragment}
                                        data-tag_action="ignore"
                                      >
                                        {({ active, selected }) => (
                                          <li className={styles.listItemWrap}>
                                            <div
                                              className={clsx(
                                                styles.listItem,
                                                selected &&
                                                  styles.listItemHighlighted,
                                                active &&
                                                  styles.listItemHighlighted,
                                                props.listItemClassName
                                              )}
                                            >
                                              <LocationIcon
                                                className={styles.icon}
                                              />

                                              <Truncate as="span">
                                                <Highlighter
                                                  className={
                                                    styles.listItemTextHighlight
                                                  }
                                                  ignoreCase
                                                  pattern={query}
                                                >
                                                  {result.label}
                                                </Highlighter>
                                              </Truncate>
                                            </div>
                                          </li>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                  </>
                                )}

                                {results.properties.length > 0 && (
                                  <>
                                    <li
                                      className={clsx(
                                        styles.listHeader,
                                        props.searchResultsHeaderClassName
                                      )}
                                    >
                                      Properties
                                    </li>

                                    {results.properties.map((result, index) => (
                                      <Combobox.Option
                                        key={result.value + index}
                                        value={result}
                                        as={Fragment}
                                      >
                                        {({ active, selected }) => (
                                          <li className={styles.listItemWrap}>
                                            <div
                                              className={clsx(
                                                styles.listItem,
                                                selected &&
                                                  styles.listItemHighlighted,
                                                active &&
                                                  styles.listItemHighlighted,
                                                props.listItemClassName
                                              )}
                                            >
                                              <HomeIcon
                                                className={styles.icon}
                                              />

                                              <Truncate as="span">
                                                <Highlighter
                                                  className={
                                                    styles.listItemTextHighlight
                                                  }
                                                  ignoreCase
                                                  pattern={query}
                                                >
                                                  {result.label}
                                                </Highlighter>
                                              </Truncate>
                                            </div>
                                          </li>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                  </>
                                )}
                              </>
                            )
                          )}
                        </>
                      )}
                    </Combobox.Options>
                  )}
              </>
            )
          }}
        </Combobox>
      </div>
    </div>
  )
}

function getIsAddressNumberSearch(query: string) {
  return query.match(/^[0-9]{5}? /)
} // If the query matches 5 numbers followed by a space, treat it as a property house number input

type FetchAndTransformComboBoxResultsParams = {
  query: string
  refinements?: string | string[]
  propertyTypes?: string | string[]
  singlePropertyTypeFilterParam?: string | string[]
  moveInDate?: SearchParams['move_in_date']
  pathName?: string | null
}

function fetchAndTransformSearchComboBoxResults({
  query,
  refinements,
  propertyTypes,
  singlePropertyTypeFilterParam,
  moveInDate,
  pathName,
}: FetchAndTransformComboBoxResultsParams) {
  const zip = query.match(/^[0-9]{5}(?:-[0-9]{4})?/) // If query has a zip code, we want to strip everything else
  const isAddressNumberSearch = getIsAddressNumberSearch(query)
  const queryToSend = isAddressNumberSearch || !zip ? query : zip?.[0] || ''

  return fetchSearchComboboxQuery({ query: queryToSend }).mapOk((value) =>
    transformSearchQueryResults({
      value,
      query: queryToSend,
      refinements,
      propertyTypes,
      singlePropertyTypeFilterParam,
      moveInDate,
      pathName,
    })
  )
}

function shouldShowResult(result: SearchComboBoxItem, query: string) {
  return (
    result?.value?.startsWith('zip-') ||
    (query.match(/^\d{4,5}$/) && result?.type === 'ZIP') ||
    result?.resultType === ResultType.PROPERTY ||
    Boolean(result?.label.length)
  )
}

type TransformSearchQueryResultsParams = {
  value: SearchComboboxQuery
  query: string
  refinements?: string | string[]
  propertyTypes?: string | string[]
  singlePropertyTypeFilterParam?: string | string[]
  moveInDate?: SearchParams['move_in_date']
  savedSearches?: SavedSearchSummary[]
  pathName?: string | null
}

function transformSearchQueryResults({
  value,
  query,
  refinements,
  propertyTypes,
  singlePropertyTypeFilterParam,
  moveInDate,
  pathName,
}: TransformSearchQueryResultsParams): SearchComboboxResults {
  const dataValue = value
  const locationSearch = dataValue.locationSearch || []
  const propertySearch = dataValue.propertySearch?.listings || []

  function handleShowResult(
    item: SearchComboBoxItem
  ): item is SearchComboBoxItem {
    return (
      null != item &&
      typeof item?.label === 'string' &&
      typeof item?.value === 'string' &&
      shouldShowResult(item, query)
    )
  }

  return {
    places: locationSearch
      .map((locationItem) => {
        const url =
          getSearchPageUrlFromLocation({
            location: locationItem,
            refinements,
            propertyTypes,
            singlePropertyTypeFilterParam,
            moveInDate,
            pathName,
          }) || ''

        return {
          label: locationItem.name || '',
          value: url,
          type: locationItem.type,
          resultType: ResultType.PLACE,
        }
      })
      .filter(handleShowResult),
    properties:
      propertySearch
        ?.slice(0, 3)
        .map((item) => ({
          label: `${item?.name}, ${item?.location?.city}, ${item?.location?.stateAbbr}`,
          value: maybePrefixWithSlash(item?.urlPathname || ''),
          dataTagItem: 'property_name',
          resultType: ResultType.PROPERTY,
        }))
        ?.filter(handleShowResult) ?? [],
  }
}
