import pino, { LoggerOptions } from 'pino'

export const pinoOptions: LoggerOptions = {
  ...(typeof window === 'undefined' ? {} : { browser: { asObject: true } }),
  level: process.env.LOG_LEVEL || 'info',
}

export const logger = pino(pinoOptions)

export function createLogger(name: string) {
  return logger.child({ name })
}
