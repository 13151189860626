import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'
import type { SearchParams } from './search-page.types'

export const RouteParamsContext = createContext<SearchParams>(
  {} as SearchParams
)

export function RouteParamsProvider<T = SearchParams>({
  children,
  routeParams,
}: {
  children: ReactNode
  routeParams: T
}) {
  return (
    <RouteParamsContext.Provider value={routeParams as SearchParams}>
      {children}
    </RouteParamsContext.Provider>
  )
}

export function useRouteParams<T = SearchParams>(): T {
  const context = useContext(RouteParamsContext)
  if (context === undefined) {
    throw new Error('useRouteParams must be used within a RouteParamsProvider')
  }
  return context as T
}
